import React from 'react'
import PropTypes from 'prop-types'

import './HeaderFrontpage.css'
import Button from '../Button/Button'

const Header = ({ className, titleBig, teaserText ,color, textcolor, children }) => {
  return (
    <>
      <header className={`w-full px-4 md:px-8 pb-6 lg:pb-16 pt-0 md:pt-16 flex justify-center bg-${color} ${className}`}>
        <div className={`flex w-full max-w-6xl`}>
          <div className={`flex flex-col md:justify-center w-full md:w-6/12 xs:mt-8 md:mt-0 md:pr-8 wrapper-stage-title`}>
              <h1 className={`stage-title w-full self-start hyphens-manual ${textcolor}`}>Per Anhalter zur Digitali&shy;sierung.</h1>
              <div id="stage-img-mobile" className="mobile-only block md:hidden">{children}</div>
              <p className='leading-relaxed text-white w-full md:mt-16 lg:mt-8 xl:mt-16 md:pr-16 hyphens'>
              Seien wir ehrlich, die Entwicklung von digitalen Produkten ist schwer. Es müssen nicht nur strategische und monetäre Ziele verfolgt werden. Viel mehr stellt sich die Frage, wie die App, Website oder Online-Plattform am effektivsten funktioniert. 
              Unsere Mission ist die bei dieser Herausforderung zu unterstützen.
              </p>
              <Button to='/projekt-starten' withArrow className='w-max-content self-start p-3 mt-4 mb-8 btn-filled btn-yellow-outline'>Projekt starten</Button>
          </div>
          <div id='stage-img' className='hidden md:block absolute overflow-x-hidden right-0 w-full md:w-3/5'>
              {children}
          </div>
        </div>
      </header>
    </>
  )
}

Header.propTypes = {
  titleBig: PropTypes.string,
  teaserText: PropTypes.string,
  color: PropTypes.string,
  textcolor: PropTypes.string,
  children: PropTypes.node,
}

Header.defaultProps = {
  color: 'cream',
}

export default Header
